import { mapState, mapGetters, mapActions } from 'vuex';

import Header from '@/components/Header/Header.vue';
import Search from '@/components/Search/Search.vue';
import UsersFilter from '@/components/UsersFilter/UsersFilter.vue';
import UsersList from '@/components/UsersList/UsersList.vue';
import UserCard from '@/components/UserCard/UserCard.vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import FilterBy from '../../components/FIlterBy/FilterBy.vue';
import EmptyMessage from '@/components/EmptyMessage';

export default {
  name: 'Users',
  components: {
    Header,
    Search,
    UsersFilter,
    UsersList,
    UserCard,
    Pagination,
    FilterBy,
    EmptyMessage,
  },

  computed: {
    ...mapState({
      page: state => state.users.filters.page,
      params: state => state.users.params,
    }),
    ...mapGetters({
      users: 'users/usersByPage',
      pageCount: 'users/pageCount',
    }),
    search: {
      get() {
        return this.$store.state.users.filters.search.value ?? '';
      },
      set(value) {
        this.setFilter({
          param: 'search',
          value: {
            value,
            param: 'username',
          },
        });
      },
    },
    isFiltersSelected() {
      return (
        (this.params.username === 'All users' ||
          this.params.username === null) &&
        (this.params.currency === null ||
          this.params.currency === 'All currencies') &&
        this.params.amount_from === null &&
        this.params.amount_to === null
      );
    },
  },
  watch: {
    search() {
      this.setFilter({
        param: 'page',
        value: 1,
      });
    },
  },
  async created() {
    this.getUsers();
  },
  methods: {
    ...mapActions({
      setFilter: 'users/setFilter',
      getUsers: 'users/getUsers',
    }),
    nextPage() {
      this.setFilter({
        param: 'page',
        value: this.page + 1,
      });
    },
  },
};
