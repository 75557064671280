export default {
  props: {
    request: Object,
  },
  computed: {
    currency() {
      return (this.request.currency || 'usd').toLocaleLowerCase();
    },
    isAllowedChangeDeposit() {
      switch (this.currency) {
        case 'usd':
        case 'rub':
          return true;
        default:
          return false;
      }
    },
    currencySign() {
      switch (this.currency) {
        default:
          return String(this.currency).toLocaleUpperCase();
      }
    },
    isFastGate() {
      return this.request?.data.transfer_gate === 'fast';
    },
  },
};
