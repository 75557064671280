import Vue from 'vue';
import VueRouter from 'vue-router';

import { getToken } from '@/api/auth';

import Login from '@/views/Login/LoginView.vue';
import Users from '@/views/Users/UsersView.vue';
import UserProfile from '@/views/UserProfile/UserProfile.vue';
import Settings from '@/views/Settings';

Vue.use(VueRouter);

const routes = [
  {
    name: 'login',
    path: '/login',
    component: Login,
  },
  {
    name: 'users',
    path: '/users',
    component: Users,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'user',
    path: '/user/:hash',
    component: UserProfile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'settings',
    path: '/settings',
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/*',
    redirect: '/login',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isRequiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const token = getToken();

  if (isRequiresAuth) {
    token ? next() : next({ name: 'login' });
  } else {
    to.name === 'login' && token ? next({ name: 'users' }) : next();
  }
});

export default router;
