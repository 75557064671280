import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=30b50257&scoped=true&"
import script from "./pagination.js?vue&type=script&lang=js&"
export * from "./pagination.js?vue&type=script&lang=js&"
import style0 from "./pagination.scss?vue&type=style&index=0&id=30b50257&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30b50257",
  null
  
)

export default component.exports