import axios from '@/plugins/axios';

export const getQrCodeLink = async () => {
  try {
    const {
      data: {
        data: { qr_code_link: qrCodeLink },
      },
    } = await axios.get('/api/manager/qr_code');

    return qrCodeLink;
  } catch (error) {
    return '';
  }
};

export const saveQrCode = async data => {
  try {
    const {
      data: {
        data: { qr_code_link: qrCodeLink },
      },
    } = await axios.post('/api/manager/qr_code', data);

    return qrCodeLink;
  } catch (error) {
    return '';
  }
};

export const deleteQrCode = async () => {
  try {
    const {
      data: { data },
    } = await axios.delete('/api/manager/qr_code');

    return data === 'success';
  } catch (error) {
    return false;
  }
};

export const clearQrCache = async url => {
  await axios
    .get(url, {
      headers: {
        'Cache-Control': 'no-cache',
      },
    })
    .catch(() => {
      /** */
    });
};
