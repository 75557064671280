import Header from '@/components/Header/Header.vue';
import UsersFilter from '@/components/UsersFilter/UsersFilter.vue';
import UserCard from '@/components/UserCard/UserCard.vue';

export default {
  name: 'UserProfile',
  components: {
    Header,
    UsersFilter,
    UserCard,
  },
};
