import axios from '@/plugins/axios';

import { mapActions } from 'vuex';

export default {
  data: () => ({
    transferTypes: {
      to: 'to',
      from: 'from',
    },
    requestTypes: {
      deposit: 'deposit',
      withdrawal: 'withdrawal',
    },
  }),
  methods: {
    ...mapActions({
      getUser: 'users/getUser',
    }),
    async transfer(data) {
      const { type, hash, body } = data;

      try {
        await axios.put(`/api/manager/users/${hash}/transfer/${type}`, body);
        this.getUser(this.user.hash);
      } catch (error) {
        return;
      }
    },
    async completeRequest(data) {
      const { type, hash } = data;

      try {
        await axios.put(`/api/manager/${type}/${hash}/complete`);
        this.getUser(this.user.hash);
      } catch (error) {
        return;
      }
    },
    async rejectRequest(data) {
      const { type, hash } = data;

      try {
        await axios.put(`/api/manager/${type}/${hash}/reject`);
        this.getUser(this.user.hash);
      } catch (error) {
        return;
      }
    },
    async deposit(data) {
      const { hash, body } = data;

      try {
        await axios.put(`/api/manager/deposit/${hash}`, body);
      } catch (error) {
        return;
      }
    },
  },
};
