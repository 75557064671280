import axios from '@/plugins/axios';

export const signin = async userData => {
  try {
    const {
      data: { data },
    } = await axios.post('/api/manager/auth/signin', userData);

    return data === 'success';
  } catch (error) {
    return false;
  }
};

export const signinContinue = async userData => {
  try {
    const {
      data: {
        data: { jwt },
      },
    } = await axios.post('/api/manager/auth/signin/continue', userData);

    return jwt;
  } catch (error) {
    return '';
  }
};

export const signout = key => {
  return axios.delete('/api/manager/auth/session', { params: { key } });
};

export const setToken = token => {
  localStorage.setItem('token', token);
};

export const getToken = () => {
  return localStorage.getItem('token');
};

export const removeToken = () => {
  localStorage.removeItem('token');
};
