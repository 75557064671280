<template>
  <span
    class="copy-field"
    @click="copy"
  >
    {{ value }}
    <v-icon
      x-small
      color="white"
      >mdi-content-copy</v-icon
    >
  </span>
</template>
<script>
export default {
  name: 'CopyField',
  props: {
    value: String,
  },
  methods: {
    async copy() {
      if (this.isCopied) return;
      try {
        await navigator.clipboard.writeText(this.value);
        this.$store.commit('showSnackbar', {
          message: `"${this.value}" copied into clipboard`,
          options: { color: 'success' },
        });
      } catch (e) {
        console.log(e);
        this.$store.commit('showSnackbar', {
          message: 'Error while copy',
          options: { color: 'red' },
        });
      }
    },
  },
};
</script>
<style scoped>
.copy-field {
  cursor: pointer;
}
</style>
