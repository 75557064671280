import { mapState, mapActions, mapGetters } from 'vuex';

import usersMixin from '@/mixins/users';

import DepositCard from '@/components/UserCard/DepositCard/DepositCard.vue';
import WithdrawCard from '@/components/UserCard/WithdrawCard/WithdrawCard.vue';

import { transferValidators } from '@/utils/validators.js';

export default {
  name: 'UserCard',
  components: {
    DepositCard,
    WithdrawCard,
  },
  mixins: [usersMixin],
  data: () => ({
    depositAmount: '',
    withdrawAmount: '',
    account: 'checking',
    typesAccounts: [
      { text: 'Checking', value: 'checking' },
      { text: 'Loan', value: 'loan' },
    ],
    transferRules: transferValidators,
  }),
  computed: {
    ...mapState({
      user: state => state.users.currentUser,
    }),
    ...mapGetters({
      withdraws: 'users/sortedWithdraws',
    }),
  },
  async created() {
    if (!this.user) {
      await this.getUser(this.$route.params.hash);

      if (!this.user) this.$router.push({ name: 'users' });
    }
  },
  beforeDestroy() {
    this.resetCurrentUser();
  },
  methods: {
    ...mapActions({
      getUser: 'users/getUser',
      resetCurrentUser: 'users/resetCurrentUser',
      setFilter: 'users/setFilter',
    }),
    async onTransfer(type) {
      const amount = {
        [this.transferTypes.to]: this.depositAmount,
        [this.transferTypes.from]: this.withdrawAmount,
      }[type];

      const transferData = {
        type,
        hash: this.user.hash,
        body: {
          amount,
          account_type: this.account,
        },
      };

      const transferMap = {
        [this.transferTypes.to]: async () => {
          const isValidData =
            this.$refs.depositForm.validate() && this.depositAmount;

          if (isValidData) await this.transfer(transferData);
        },
        [this.transferTypes.from]: async () => {
          const isValidData =
            this.$refs.withdrawForm.validate() && this.withdrawAmount;

          if (isValidData) await this.transfer(transferData);
        },
      };

      transferMap[type]();

      this.depositAmount = '';
      this.withdrawAmount = '';
    },
  },
};
