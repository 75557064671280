import {
  signin,
  signinContinue,
  signout,
  setToken,
  removeToken,
} from '@/api/auth';

export default {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  actions: {
    async login(_, payload) {
      return await signin(payload);
    },
    async loginContinue(_, payload) {
      const token = await signinContinue(payload);

      if (token) setToken(token);
    },
    async logout(_, key) {
      await signout(key);
      removeToken();
    },
  },
  getters: {},
};
