import axios from 'axios';
import router from '@/router';
import store from '@/store';

import { getToken } from '@/api/auth';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
});

instance.interceptors.request.use(config => {
  const token = getToken();

  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
});

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      router.push({ name: 'login' });
    }

    const errorText =
      error.response?.data?.error?.text ||
      error.message ||
      error.response?.data?.message;

    if (errorText) {
      store.dispatch('showSnackbar', {
        message: errorText,
        options: {},
      });
    }

    return Promise.reject(error);
  }
);

export default instance;
