import Header from '@/components/Header/Header.vue';
import QrCode from '@/components/QrCode/QrCode.vue';
import Search from '@/components/Search/Search.vue';
import ApkVersions from '@/components/ApkVersions';

export default {
  name: 'Settings',
  components: {
    Header,
    QrCode,
    Search,
    ApkVersions,
  },

  data() {
    return {
      sections: ['Versions'],
      search: '',
    };
  },

  methods: {
    onSearch(value) {
      this.search = value.toLowerCase();
    },
  },
};
