export default {
  name: 'Snackbar',
  data: () => ({
    show: false,
    message: '',
    options: {},
    defaultOptions: { color: '#dd3500', timeout: '3000' },
    storeSubscription: () => {},
  }),
  beforeMount() {
    this.storeSubscription = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'showSnackbar') {
        this.message = state.messageSnackbar;

        this.options = Object.keys(state.optionsSnackbar).length
          ? { ...this.defaultOptions, ...state.optionsSnackbar }
          : { ...this.defaultOptions };

        this.show = true;
      }
    });
  },
  beforeDestroy() {
    this.storeSubscription();
  },
};
