import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/store/auth';
import users from '@/store/users';

Vue.use(Vuex);

export default new Vuex.Store({
  state: () => ({
    messageSnackbar: '',
    optionsSnackbar: {},
    isLoading: false,
  }),
  mutations: {
    showSnackbar(state, { message, options }) {
      state.messageSnackbar = message;
      state.optionsSnackbar = options;
    },
    setLoading(state, value) {
      state.isLoading = value;
    },
  },
  actions: {
    setLoading({ commit }, value) {
      commit('setLoading', value);
    },
    showSnackbar({ commit }, payload) {
      commit('showSnackbar', payload);
    },
  },
  modules: {
    auth,
    users,
  },
});
