import axios from '@/plugins/axios';

export const getApkVersions = async () => {
  try {
    const { data } = await axios.get('/api/manager/apk');

    return data.data.versions;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const updateApkVersionDescription = async ({ id, description }) => {
  try {
    const { data } = await axios.put('/api/manager/apk/description', {
      id,
      description,
    });

    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const uploadNewApkVersion = async ({
  apk,
  versionCode,
  versionName,
  description,
}) => {
  try {
    const formData = new FormData();
    formData.append('apk', apk);
    formData.append('version_code', versionCode);
    formData.append('version', versionName);
    formData.append('description', description);

    const { data } = await axios.post('/api/manager/apk/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return data.data.is_uploaded;
  } catch (error) {
    console.log(error);
  }
};
