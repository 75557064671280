import { mapActions } from 'vuex';
import QrCode from '@/components/QrCode/QrCode.vue';

export default {
  name: 'Header',
  components: {
    QrCode,
  },
  computed: {
    isOnSettingsPage() {
      return this.$route.path.includes('settings');
    },
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    async onLogout() {
      await this.logout();
      this.$router.push({ name: 'login' });
    },
  },
};
