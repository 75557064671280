var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"px-5 py-4 mb-3",attrs:{"color":"primary"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column white--text"},[_c('span',{staticClass:"font-weight-bold honey--text"},[_vm._v(" "+_vm._s(_vm.request.amount_fiat)+" "+_vm._s(_vm.currencySign)+" "),(_vm.isFastGate)?_c('v-chip',{staticClass:"ml-2",attrs:{"color":"honey","small":"","dark":""}},[_vm._v("FASTEST")]):_vm._e()],1),_c('span',[_vm._v(_vm._s(_vm.request.amount_sc)+" SC, "),_c('span',{staticClass:"iron--text"},[_vm._v(_vm._s(_vm.request.commission)+" SC commission")])]),_c('WithdrawCardFields',{attrs:{"currency":_vm.request.currency,"fields":_vm.request.data}}),(_vm.request.transaction_type === 'By card details')?_c('div',{staticClass:"transactionType"},[_c('span',[_c('img',{attrs:{"src":_vm.icons['Arllecta pay (RUB request using card details)']}})]),_vm._v(" Bank "+_vm._s(_vm.request.data.transfer_method)+" transfer ")]):_c('div',{staticClass:"transactionType"},[_c('span',[_c('img',{attrs:{"src":_vm.icons[_vm.request.transaction_type]}})]),_vm._v(" "+_vm._s(_vm.request.transaction_type)+" ")])],1),_c('div',{staticClass:"d-flex flex-column pa-3 ml-auto white--text",class:{
        'justify-space-between': _vm.actions,
        'justify-end': !_vm.actions,
      }},[(_vm.actions)?[_c('div',{staticClass:"d-flex flex-column"},[_c('v-btn',{staticClass:"mb-3 text-capitalize white--text",attrs:{"small":"","color":"honey"},on:{"click":function($event){return _vm.completeRequest({
                type: _vm.requestTypes.withdrawal,
                hash: _vm.request.hash,
              })}}},[_vm._v(" ✓Accept ")]),_c('v-btn',{staticClass:"mb-1 text-capitalize white--text",attrs:{"small":"","color":"steel"},on:{"click":function($event){return _vm.rejectRequest({
                type: _vm.requestTypes.withdrawal,
                hash: _vm.request.hash,
              })}}},[_vm._v(" Reject ")])],1),_c('span',{staticClass:"mt-auto text-caption"},[_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.request.created_at))+" ")])]:[_c('span',{staticClass:"d-block text-caption smoke--text"},[_vm._v(" Updated: "),_c('br'),_vm._v(" "+_vm._s(_vm.request && _vm.request.updated_at ? _vm.formatDateTime(_vm.request.updated_at) : 'No data')+" ")])]],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }