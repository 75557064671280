const formatterDate = Intl.DateTimeFormat('en-GB');
const formatterDateTime = Intl.DateTimeFormat('en-GB', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
});

export const toLocaleDate = date => {
  const dateObj = new Date(date);
  return isNaN(dateObj.valueOf()) ? '' : formatterDate.format(dateObj);
};

export const toLocaleDateTime = date => {
  const dateObj = new Date(date);
  return isNaN(dateObj.valueOf()) ? '' : formatterDateTime.format(dateObj);
};
