import card from '@/assets/incons/card.svg';
import doc from '@/assets/incons/doc.svg';
import refresh from '@/assets/incons/refresh.svg';
import btc from '@/assets/incons/btc.svg';
import qr from '@/assets/incons/qr.svg';
import crypto from '@/assets/incons/crypto.svg';

export const months = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};

export const fileTypes = ['B', 'KB', 'MB', 'GB', 'TB'];

export const transactionIcons = {
  'By card details': card,
  'QR-Code': qr,
  'Crypto Wallet': crypto,
  'Crypto wallet (BTC wallet)': btc,
  'Arllecta pay (request in other currencies using card details)': refresh,
  'Arllecta pay (RUB request using card details)': doc,
};
