import userFilter from '@/utils/userFilter';

import { fetchUsers, fetchUser } from '@/api/users';
import { fetchFilters } from '../../api/users';

const getDefaultFilter = () => ({
  type: 'all',
  search: { param: 'username', value: '' },
  page: 1,
  perPage: 25,
});

const getFilterDefaultValues = () => ({
  all_currencies: [],
  deposit_currencies: [],
  usernames: [],
  withdrawal_currencies: [],
});

const getDefaultParams = () => ({
  username: null,
  currency: null,
  amount_from: null,
  amount_to: null,
});

export default {
  namespaced: true,
  state: () => ({
    users: [],
    currentUser: null,
    filters: getDefaultFilter(),
    filterValues: getFilterDefaultValues(),
    params: getDefaultParams(),
  }),
  mutations: {
    setUsers(state, users) {
      state.users = users;
    },
    setUser(state, user) {
      state.currentUser = user;
    },
    setFilter(state, { param, value }) {
      state.filters[param] = value;
    },
    setParams(state, params) {
      state.params = params;
    },
    setFilterValues(state, filters) {
      state.filterValues = filters;
    },
  },
  actions: {
    async getUsers({ state, commit, dispatch }) {
      try {
        dispatch('setLoading', true, { root: true });

        const users = await fetchUsers(state.filters.type ?? 'all', {
          ...state.params,
          username:
            state.params.username === 'All users'
              ? null
              : state.params.username,
          currency:
            state.params.currency === 'All currencies'
              ? null
              : state.params.currency,
          amount_from: !state.params.amount_from
            ? null
            : state.params.amount_from,
          amount_to: !state.params.amount_to ? null : state.params.amount_to,
        });

        commit('setUsers', users);

        dispatch('setLoading', false, { root: true });
      } catch (error) {
        return;
      }
    },
    async getUser({ commit, dispatch }, payload) {
      try {
        dispatch('setLoading', true, { root: true });

        const user = await fetchUser(payload);

        commit('setUser', user);

        dispatch('setLoading', false, { root: true });
      } catch (error) {
        return;
      }
    },
    async getFilters({ commit, dispatch }) {
      try {
        dispatch('setLoading', true, { root: true });

        const { data: filters } = await fetchFilters();
        commit('setFilterValues', filters);

        dispatch('setLoading', false, { root: true });
      } catch (error) {
        return;
      }
    },
    resetCurrentUser({ commit }) {
      commit('setUser', null);
    },
    setFilter({ commit }, payload) {
      commit('setFilter', payload);
    },
    setParams({ commit }, payload) {
      commit('setParams', payload);
    },
    setDefaultParams({ commit }) {
      commit('setParams', getDefaultParams());
    },
  },
  getters: {
    filteredUsers(state) {
      const filter = new userFilter(state.users, state.filters);

      return filter.applySearchFilter().getUsers();
    },
    usersByPage(state, getters) {
      const filter = new userFilter(getters.filteredUsers, {
        page: state.filters.page,
        perPage: state.filters.perPage,
      });

      return filter.applyPageFilter().getUsers();
    },
    pageCount(state, getters) {
      return Math.ceil(getters.filteredUsers.length / state.filters.perPage);
    },
    sortedWithdraws(state) {
      return Object.entries(state.currentUser?.withdrawals ?? {}).reduce(
        (acc, [key, values]) => {
          const sortedValues = values.slice().sort((a, b) => {
            const [aGate, bGate] = [a, b].map(item => item.data.transfer_gate);
            const [aDate, bDate] = [a, b].map(item => item.created_at);
            if (aGate !== bGate) {
              return aGate === 'fast' ? -1 : bGate === 'gate' ? 1 : 0;
            }
            return new Date(aDate).valueOf() - new Date(bDate).valueOf();
          });
          acc[key] = sortedValues;
          return acc;
        },
        {}
      );
    },
  },
};
