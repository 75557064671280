import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const opts = {
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        background: '#5e5e5e',
        primary: '#1b1b1b',
        orange: '#f58722',
        grey: '#5e5e5e',
        red: '#dd3500',
        brown: '#2f2f2f',
        honey: '#efa81d',
        steel: '#7c7575',
        iron: '#626262',
        ash: '#575757',
        smoke: '#a7a7a7',
        fossil: '#d9d9d9',
      },
    },
  },
};

export default new Vuetify(opts);
