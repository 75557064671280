import Vue from 'vue';
import { apkValidators } from '@/utils/validators';
import { updateApkVersionDescription } from '@/api/apk';

export default Vue.extend({
  name: 'UpdateApkDescriptionModal',
  props: {
    isShow: Boolean,
    version: Object,
    refetchApkVersions: Function,
  },

  data() {
    return {
      isLoading: false,
      description: '',
      descriptionRule: apkValidators.description,
    };
  },

  methods: {
    closeModal() {
      this.$emit('close');
      this.resetForm();
    },

    resetForm() {
      this.description = this.version.description;
      this.$refs.form.resetValidation();
    },

    async updateDescription() {
      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      this.isLoading = true;

      const response = await updateApkVersionDescription({
        id: this.version.id,
        description: this.description,
      });

      this.isLoading = false;

      if (response === 'success') {
        this.refetchApkVersions();
        this.closeModal();

        this.$store.commit('showSnackbar', {
          message: 'Description updated',
          options: { color: 'success' },
        });
      }
    },
  },

  watch: {
    version: {
      handler(newVal) {
        this.description = newVal.description || '';
      },
      immediate: true,
    },
  },
});
