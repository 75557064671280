import currency from '@/mixins/currency';
import datetime from '@/mixins/datetime';
import WithdrawCardFields from './withdrawCardFields';
import { transactionIcons } from '@/utils/constants';

export default {
  name: 'WithdrawCard',
  mixins: [datetime, currency],
  components: {
    WithdrawCardFields,
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
    requestTypes: {
      type: Object,
    },
    actions: {
      type: Boolean,
      default: false,
    },
    completeRequest: {
      type: Function,
    },
    rejectRequest: {
      type: Function,
    },
  },
  computed: {
    icons() {
      return transactionIcons;
    },
  },
};
