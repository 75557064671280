export default {
  name: 'Pagination',
  props: {
    page: {
      type: Number,
      required: true,
    },
    pageCount: {
      type: Number,
      required: true,
    },
    nextPage: {
      type: Function,
      required: true,
    },
  },
  computed: {
    isNextPage() {
      return this.page + 1 <= this.pageCount;
    },
  },
};
