import { mapActions, mapState } from 'vuex';

const defaultForm = {
  username: 'All users',
  currency: 'All currencies',
  amount_from: '',
  amount_to: '',
};

export default {
  name: 'FilterBy',
  data() {
    return {
      dialog: false,
      filters: {
        all_currencies: [],
        deposit_currencies: [],
        usernames: [],
        withdrawal_currencies: [],
      },
      form: { ...defaultForm },
      filtersForm: {
        userName: '',
        currency: '',
      },
      isSubmitted: false,
    };
  },
  methods: {
    ...mapActions({
      getUsers: 'users/getUsers',
      setParams: 'users/setParams',
      getFilters: 'users/getFilters',
      setDefaultParams: 'users/setDefaultParams',
    }),
    async submit() {
      this.checkAmounts();
      await this.setParams({ ...this.form });
      this.getUsers();
      this.isSubmitted = true;
      this.dialog = false;
    },
    open() {
      this.resetAmounts();
      this.filtersForm.userName = '';
      this.filtersForm.currency = '';
      this.dialog = true;
    },
    async close() {
      if (this.isSubmitted) {
        this.form = { ...this.params };
        this.dialog = false;
        return;
      } else {
        this.resetAmounts();
        this.resetForm();
        await this.setParams({ ...this.form });
        this.getUsers();
        this.dialog = false;
      }
    },
    resetAmounts() {
      this.form.amount_from = '';
      this.form.amount_to = '';
    },
    resetForm() {
      this.form = { ...defaultForm };
    },
    checkAmounts() {
      this.form.amount_from <= 0
        ? (this.form.amount_from = '')
        : (this.form.amount_from = Math.floor(this.form.amount_from));
      this.form.amount_to <= 0
        ? (this.form.amount_to = '')
        : (this.form.amount_to = Math.ceil(this.form.amount_to));
    },
    async clearFilters() {
      this.resetForm();
      await this.setParams({ ...this.form });
      this.getUsers();
    },
  },
  async created() {
    await this.getFilters();
    this.filters = { ...this.filterValues };
  },

  computed: {
    ...mapState({
      type: state => state.users.filters.type,
      params: state => state.users.params,
      filterValues: state => state.users.filterValues,
    }),
    isFormDefault() {
      return JSON.stringify(this.form) === JSON.stringify(defaultForm);
    },
    currencyItems() {
      this.isSubmitted = false;
      switch (this.type) {
        case 'all': {
          this.resetForm();

          return ['All currencies', ...this.filters.all_currencies].filter(c =>
            c
              .toLocaleLowerCase()
              .includes(this.filtersForm.currency.toLocaleLowerCase())
          );
        }
        case 'deposit': {
          this.resetForm();
          return ['All currencies', ...this.filters.deposit_currencies].filter(
            c =>
              c
                .toLocaleLowerCase()
                .includes(this.filtersForm.currency.toLocaleLowerCase())
          );
        }
        case 'withdrawal': {
          this.resetForm();

          return [
            'All currencies',
            ...this.filters.withdrawal_currencies,
          ].filter(c =>
            c
              .toLocaleLowerCase()
              .includes(this.filtersForm.currency.toLocaleLowerCase())
          );
        }
        default: {
          return ['All currencies', ...this.filters.all_currencies].filter(c =>
            c
              .toLocaleLowerCase()
              .includes(this.filtersForm.currency.toLocaleLowerCase())
          );
        }
      }
    },
    userItems() {
      return ['All users', ...this.filters.usernames].filter(u =>
        u
          .toLocaleLowerCase()
          .includes(this.filtersForm.userName.toLocaleLowerCase())
      );
    },
  },
  beforeDestroy() {
    this.setDefaultParams();
  },
};
