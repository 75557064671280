const regExpValidators = {
  accessCode: /^[0-9A-Za-z]{64}$/,
  email:
    /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/,
};

export const apkValidators = {
  description: [
    v => !!v || 'Enter description',
    v =>
      (v.length >= 2 && v.length <= 200) ||
      'Description must contain 2-200 characters',
  ],
  versionCode: [v => !!v || 'Enter version code'],
  versionName: [v => !!v || 'Enter version name'],
};

const validators = {
  required: value => !!value || 'Field is required',
  emailMatch: value => regExpValidators.email.test(value) || 'Invalid email',
  accessCodeMatch: value =>
    regExpValidators.accessCode.test(value) || 'Invalid access code',
  transfer: value => {
    if (parseFloat(value) < 0.1) return 'Minimum amount 0.1';
    if (value.split('.')[1]?.length > 6) return 'Invalid amount';
    return true;
  },
};

export const emailValidators = [validators.required];

export const passwordValidators = [validators.required];

export const transferValidators = [validators.transfer];

export const accessCodeValidators = [validators.required];

export const getValidDepositAmount = (amount, defaultAmount) => {
  if (!amount) return defaultAmount;

  if (parseFloat(amount) < 10) return 10;

  if (parseFloat(amount) > 100000) return 100000;

  if (amount.split('.')[1]?.length > 2)
    return Math.floor(parseFloat(amount) * 100) / 100;

  return amount;
};
