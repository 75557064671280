import { mapState, mapActions } from 'vuex';

import { getValidDepositAmount } from '@/utils/validators.js';
import currency from '@/mixins/currency';
import datetime from '@/mixins/datetime';
import { transactionIcons } from '@/utils/constants';

export default {
  name: 'DepositCard',
  components: {
    CopyField: () => import('@/components/CopyField'),
  },
  mixins: [currency, datetime],
  props: {
    request: {
      type: Object,
      required: true,
    },
    requestTypes: {
      type: Object,
    },
    actions: {
      type: Boolean,
      default: false,
    },
    completeRequest: {
      type: Function,
    },
    rejectRequest: {
      type: Function,
    },
    deposit: {
      type: Function,
    },
  },
  data: vm => ({
    amount: vm.request.amount_fiat,
    isChangeAmount: false,
  }),
  computed: {
    ...mapState({
      user: state => state.users.currentUser,
    }),
    icons() {
      return transactionIcons;
    },
  },
  methods: {
    ...mapActions({
      getUser: 'users/getUser',
    }),
    setIsChangeAmount(value) {
      this.isChangeAmount = value;
    },
    async onSaveAmount() {
      this.amount = getValidDepositAmount(this.amount);

      if (+this.amount !== +this.request.amount_fiat) {
        await this.deposit({
          hash: this.request.hash,
          body: { amount_fiat: `${this.amount}` },
        });

        this.getUser(this.user.hash);
      }

      this.setIsChangeAmount(false);
    },
    cancelAmount() {
      this.amount = this.request.amount_fiat;
      this.setIsChangeAmount(false);
    },
  },
};
