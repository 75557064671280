export default class userFilter {
  constructor(users, filters) {
    const { search, page, perPage } = filters;

    this.users = users;
    this.search = search;
    this.page = page;
    this.perPage = perPage;
  }

  applySearchFilter() {
    const searchValue = this.search.value.toLowerCase();

    if (searchValue) {
      this.users = this.users.filter(user => {
        const param = user[this.search.param].toLowerCase();

        return param.includes(searchValue);
      });
    }

    return this;
  }

  applyPageFilter() {
    this.users = this.users.slice(0, this.perPage * this.page);

    return this;
  }

  getUsers() {
    return this.users;
  }
}
