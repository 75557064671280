import { render, staticRenderFns } from "./DepositCard.vue?vue&type=template&id=57fe1344&scoped=true&"
import script from "./depositCard.js?vue&type=script&lang=js&"
export * from "./depositCard.js?vue&type=script&lang=js&"
import style0 from "./depositCard.scss?vue&type=style&index=0&id=57fe1344&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57fe1344",
  null
  
)

export default component.exports