import { getApkVersions } from '@/api/apk';
import UpdateApkDescriptionModal from '@/components/modals/UpdateApkDescriptionModal';
import AddNewApkVersionModal from '@/components/modals/AddNewApkVersionModal';
import EmptyMessage from '@/components/EmptyMessage';
import ApkVersionsItem from './ApkVersionsItem';

export default {
  name: 'ApkVersions',
  components: {
    ApkVersionsItem,
    UpdateApkDescriptionModal,
    EmptyMessage,
    AddNewApkVersionModal,
  },

  props: {
    search: String,
  },

  data() {
    return {
      sortItems: [
        { text: 'From new to old', value: 'newToOld' },
        { text: 'From old to new', value: 'oldToNew' },
      ],
      selectedSort: 'newToOld',
      versions: [],
      currentVersion: {},
      modals: {
        update: false,
        add: false,
      },
      isLoading: false,
    };
  },

  computed: {
    filteredVersions() {
      return this.versions.filter(({ description, version }) => {
        const lcDescription = description.toLowerCase();
        const lcVersion = version.toLowerCase();

        return (
          lcDescription.includes(this.search) || lcVersion.includes(this.search)
        );
      });
    },
  },

  methods: {
    toggleModal(modalType, version = {}) {
      this.modals[modalType] = !this.modals[modalType];
      this.currentVersion = version;
    },

    refetchApkVersions() {
      this.fetchApkVersions();
    },

    async fetchApkVersions() {
      this.isLoading = true;
      this.versions = await getApkVersions();
      this.isLoading = false;
    },

    onSortChange() {
      this.versions = this.versions.reverse();
    },
  },

  async created() {
    await this.fetchApkVersions();
  },
};
