import { mapState, mapActions } from 'vuex';

export default {
  name: 'UsersFilter',
  data: () => ({
    filters: [
      {
        label: 'Users',
        value: 'all',
      },
      {
        label: 'Deposit requests',
        value: 'deposit',
      },
      {
        label: 'Withdraw requests',
        value: 'withdrawal',
      },
    ],
  }),
  computed: {
    ...mapState({
      type: state => state.users.filters.type,
    }),
  },
  methods: {
    ...mapActions({
      getUsers: 'users/getUsers',
      setFilter: 'users/setFilter',
      setDefaultParams: 'users/setDefaultParams',
    }),
    async onChangeFilter(value) {
      this.setDefaultParams();
      if (value != this.type) {
        this.setFilter({
          value,
          param: 'type',
        });

        this.setFilter({
          param: 'page',
          value: 1,
        });

        this.getUsers();

        if (this.$route.name !== 'users') this.$router.push({ name: 'users' });
      }
    },
  },
};
