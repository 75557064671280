import axios from '@/plugins/axios';

export const fetchUsers = async (type = 'all', filters) => {
  const url = {
    all: '/api/manager/users',
    deposit: '/api/manager/users/deposit',
    withdrawal: '/api/manager/users/withdrawal',
  }[type];
  const {
    data: {
      data: { users },
    },
  } = await axios.get(url, { params: filters });

  return users;
};

export const fetchUser = async hash => {
  const {
    data: { data },
  } = await axios.get(`/api/manager/users/${hash}`);

  return data;
};
export const fetchFilters = async () => {
  const { data: data } = await axios.get(`/api/manager/filters`);
  return data;
};
