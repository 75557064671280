import {
  getQrCodeLink,
  saveQrCode,
  deleteQrCode,
  clearQrCache,
} from '@/api/qr-code';

export default {
  name: 'QrCode',
  data: () => ({
    file: null,
    qrCodeLink: '',
    isQrCodeAvailable: false,
    isShowQrCode: false,
    isLoadingQrCodeLink: false,
    imgKey: 0,
  }),
  computed: {
    buttonText() {
      if (this.file) return 'Save QR Code';

      if (!this.file && this.isQrCodeAvailable) return 'Update QR Code';

      return 'Upload QR Code';
    },
  },
  async mounted() {
    this.isLoadingQrCodeLink = true;
    this.qrCodeLink = await getQrCodeLink();
    this.isLoadingQrCodeLink = false;
  },
  methods: {
    onFileChanged(event) {
      this.file = event.target.files[0];
    },
    cancelFile() {
      this.$refs.uploader.value = null;
      this.file = null;
    },
    async qrCodeHandler() {
      this.file ? this.saveQrCode() : this.$refs.uploader.click();
    },
    async saveQrCode() {
      this.isLoadingQrCodeLink = true;
      const formData = new FormData();
      formData.append('qr_code', this.file);

      const qrCodeLink = await saveQrCode(formData);

      if (qrCodeLink) {
        this.cancelFile();
        await clearQrCache(qrCodeLink);
        this.qrCodeLink = qrCodeLink;
        this.isQrCodeAvailable = true;
        this.imgKey += 1;
      }

      this.isLoadingQrCodeLink = false;
    },
    async deleteQrCode() {
      const isSuccessfullyDeleted = await deleteQrCode();

      if (isSuccessfullyDeleted) {
        this.qrCodeLink = '';
        this.isQrCodeAvailable = false;
      }
    },
  },
};
