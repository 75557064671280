import { getMonthAndYear } from '@/utils/getMonthAndYear';
import ItemCol from './ItemCol';

export default {
  name: 'ApkVersionsItem',
  components: {
    ItemCol,
  },
  props: {
    version: Object,
  },
  methods: {
    getMonthAndYear,
  },
};
