import { toLocaleDate, toLocaleDateTime } from '@/utils/datetime';

export default {
  methods: {
    formatDate(date) {
      return toLocaleDate(date);
    },
    formatDateTime(date) {
      return toLocaleDateTime(date);
    },
  },
};
