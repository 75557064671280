import { fileTypes } from './constants';

export const formatFileSize = sizeInBytes => {
  let index = 0;
  let size = sizeInBytes;

  while (size >= 1024 && index < fileTypes.length - 1) {
    size /= 1024;
    index++;
  }

  return `${size.toFixed(2)} ${fileTypes[index]}`;
};
