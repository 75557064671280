import { mapGetters } from 'vuex';

import UsersItem from '@/components/UsersList/UsersItem/UsersItem.vue';

export default {
  name: 'UsersList',
  components: {
    UsersItem,
  },
  computed: {
    ...mapGetters({
      users: 'users/usersByPage',
    }),
  },
};
