import { mapState, mapActions } from 'vuex';

export default {
  name: 'UsersItem',
  props: {
    user: Object,
  },
  computed: {
    ...mapState({
      currentUser: state => state.users.currentUser,
    }),
    numberRequests() {
      return this.user.withdrawal?.length || this.user.deposits?.length || 0;
    },
  },
  methods: {
    ...mapActions({
      getUser: 'users/getUser',
    }),
    async onSelectUser(hash) {
      await this.getUser(hash);

      if (this.currentUser)
        this.$router.push({ name: 'user', params: { hash: hash } });
    },
  },
};
