export default {
  name: 'Search',
  props: {
    value: String,
  },
  data() {
    return {
      search: this.value,
    };
  },
  methods: {
    onInput() {
      this.$emit('input', this.search);
    },
  },
};
