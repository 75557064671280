<template>
  <v-app>
    <v-main class="pt-4">
      <router-view />
    </v-main>
    <Snackbar />
    <Loader v-show="isLoading" />
  </v-app>
</template>

<script>
import { mapState } from 'vuex';

import Snackbar from '@/components/Snackbar/Snackbar.vue';
import Loader from '@/components/Loader/Loader.vue';

export default {
  name: 'App',
  components: {
    Snackbar,
    Loader,
  },
  data: () => ({}),
  computed: {
    ...mapState({
      isLoading: state => state.isLoading,
    }),
  },
};
</script>

<style src="@/assets/styles/main.scss" lang="scss"></style>
